import React, { Fragment, useContext, useEffect, useState } from "react"
import classNames from "classnames"
import { navigate } from "gatsby"
import {
  GATSBY_PBM_ZENDESK_SUBDOMAIN,
  GATSBY_PBM_ZENDESK_API_KEY,
  GATSBY_PBM_ZENDESK_EMAIL,
} from "gatsby-env-variables"

import Layout from "layout/Layout"
import Section from "elements/Section"
import Container from "layout/Container"
import MedicineBox from "elements/MedicineBox"
import ActionButtons from "elements/ActionButtons"
import EditDetailsButton from "../../../Summary/EditDetailsButton"
import ConsentAndAuthorization from "../../../Summary/ConsentAndAuthorization"
import FileThumbnail from "../../../Summary/UploadedDocumentsSummary/FileThumbnail"

import {
  getZendeskTemplateUpdatedPersonalDetails,
  getZendeskTemplateUpdatedPrescription,
  getZendeskTemplateRefillCancellation,
  getZendeskTemplateTeleconsult,
} from "../../services/getZendeskTemplate"
import styles from "../../utils/medensure.module.scss"
import { isBrowser } from "../../../../services/general"
import { AppContext } from "../../../../context/AppContext"
import { sendToZendesk } from "../../../../services/zendeskService"

const UpdatedPrescriptionSummary = ({ pageContext }) => {
  const [loading, setLoading] = useState(false)
  const { state, dispatch } = useContext(AppContext)
  const [selectedServices, setSelectedServices] = useState([])
  const { updatedPrescription, updatePersonalDetails, epharmacy } = state
  const TEST_ENV = process.env.GATSBY_ENV === "production" ? "" : "[TEST] "
  const generateZendeskPayloadUpdatedPrescription = () => {
    let subject = "Updated Prescription Form"
    let tags = ["updated_prescription"]
    if (TEST_ENV) tags = [...tags, "test"]

    return {
      type: "request",
      subject: `${TEST_ENV}${subject}`,
      requester: {
        name: `${updatedPrescription?.firstName} ${updatedPrescription?.lastName}`,
        email: updatedPrescription?.email,
      },
      comment: {
        body: getZendeskTemplateUpdatedPrescription({
          values: updatedPrescription,
        }),
      },
      tags,
    }
  }

  // let houseNumber = updatePersonalDetails?.deliveryAddress?.houseNumber
  // let streetAddress = updatePersonalDetails?.deliveryAddress?.streetAddress
  // let city = updatePersonalDetails?.deliveryAddress?.city
  // let province = updatePersonalDetails?.deliveryAddress?.province
  // let addressType = updatePersonalDetails?.deliveryAddress?.addressType
  // let notes = updatePersonalDetails?.deliveryAddress?.notes
  // let officeAddress = updatePersonalDetails?.deliveryAddress?.officeAddress
  // let barangay = updatePersonalDetails?.deliveryAddress?.barangay

  let {
    houseNumber,
    streetAddress,
    city,
    province,
    barangay,
    addressType,
    notes,
    officeAddress,
  } = updatePersonalDetails?.deliveryAddress

  const generateZendeskPayloadPersonalDetail = () => {
    let subject = "Updated Personal Details"
    let tags = ["updated_personal_details"]
    if (TEST_ENV) tags = [...tags, "test"]

    return {
      type: "request",
      subject: `${TEST_ENV}${subject}`,
      requester: {
        name: `${updatedPrescription?.firstName} ${updatedPrescription?.lastName}`,
        email: updatedPrescription?.email,
      },
      comment: {
        body: getZendeskTemplateUpdatedPersonalDetails({
          values: updatePersonalDetails,
          updatedPrescription: updatedPrescription,
        }),
      },
      tags,
    }
  }

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [])

  const generateZendeskPayloadRefillCancellation = () => {
    let subject = "Refill Cancellation Form"
    let tags = ["refill_cancellation"]
    if (TEST_ENV) tags = [...tags, "test"]

    return {
      type: "request",
      subject: `${TEST_ENV}${subject}`,
      requester: {
        name: `${updatedPrescription?.firstName} ${updatedPrescription?.lastName}`,
        email: updatedPrescription?.email,
      },
      comment: {
        body: getZendeskTemplateRefillCancellation({
          values: updatedPrescription,
        }),
      },
      tags,
    }
  }

  const generateZendeskPayloadTeleconsult = () => {
    let subject = "Teleconsult Form"
    let tags = ["teleconsult"]
    if (TEST_ENV) tags = [...tags, "test"]

    return {
      type: "request",
      subject: `${TEST_ENV}${subject}`,
      requester: {
        name: `${updatedPrescription?.firstName} ${updatedPrescription?.lastName}`,
        email: updatedPrescription?.email,
      },
      comment: {
        body: getZendeskTemplateTeleconsult({ values: updatedPrescription }),
      },
      tags,
    }
  }

  const handleSubmitUpdatedPrescription = async () => {
    const requestPayload = generateZendeskPayloadUpdatedPrescription()
    await sendToZendesk(requestPayload, state.documents, null, {
      apiUrl: GATSBY_PBM_ZENDESK_SUBDOMAIN,
      apiKey: GATSBY_PBM_ZENDESK_API_KEY,
      apiEmail: GATSBY_PBM_ZENDESK_EMAIL,
    })

    dispatch({ type: "SAVE_AGREE_EPHARMACY", payload: [] })
  }

  const handleSubmitUpdatedPersonalDetails = async () => {
    const requestPayload = generateZendeskPayloadPersonalDetail()
    await sendToZendesk(requestPayload, state.documents, null, {
      apiUrl: GATSBY_PBM_ZENDESK_SUBDOMAIN,
      apiKey: GATSBY_PBM_ZENDESK_API_KEY,
      apiEmail: GATSBY_PBM_ZENDESK_EMAIL,
    })

    dispatch({ type: "SAVE_AGREE_EPHARMACY", payload: [] })
  }

  const handleSubmitRefillCancellation = async () => {
    const requestPayload = generateZendeskPayloadRefillCancellation()
    await sendToZendesk(requestPayload, [], null, {
      apiUrl: GATSBY_PBM_ZENDESK_SUBDOMAIN,
      apiKey: GATSBY_PBM_ZENDESK_API_KEY,
      apiEmail: GATSBY_PBM_ZENDESK_EMAIL,
    })

    dispatch({ type: "SAVE_AGREE_EPHARMACY", payload: [] })
  }

  const handleSubmitTeleconsult = async () => {
    const requestPayload = generateZendeskPayloadTeleconsult()
    await sendToZendesk(requestPayload, [], null, {
      apiUrl: GATSBY_PBM_ZENDESK_SUBDOMAIN,
      apiKey: GATSBY_PBM_ZENDESK_API_KEY,
      apiEmail: GATSBY_PBM_ZENDESK_EMAIL,
    })
  }

  const handleOnSubmit = async () => {
    setLoading(true)
    try {
      if (selectedServices?.includes("Upload updated prescription")) {
        await handleSubmitUpdatedPrescription()
      }
      if (selectedServices?.includes("Update personal details")) {
        await handleSubmitUpdatedPersonalDetails()
      }
      if (selectedServices?.includes("Cancel refills")) {
        await handleSubmitRefillCancellation()
      }
      if (selectedServices?.includes("Book a free teleconsult")) {
        // await handleSubmitTeleconsult()
        navigate("/medensure/schedule")
      } else {
        navigate(pageContext?.nextPath)
      }
    } catch {
      setLoading(false)
    }
  }

  const getBackPath = () => {
    if (selectedServices?.includes("Upload updated prescription")) {
      return `/medensure/updated-prescription`
    }
    if (selectedServices?.includes("Update personal details")) {
      return `/medensure/update-personal-details`
    }
    if (selectedServices?.includes("Cancel refills")) {
      return `/medensure/cancel-refills`
    }
    if (selectedServices?.includes("Book a free teleconsult")) {
      return `/medensure/health-survey`
    } else {
      return `/medensure/`
    }
  }

  useEffect(() => {
    if (isBrowser()) {
      setSelectedServices(
        JSON.parse(sessionStorage.getItem("medensureServices"))
      )
    }
  }, [])

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.title}
      pageContext={pageContext}
      display={{ footer: false, helpCenterBanner: false }}
      path="/medensure"
    >
      <Container isCentered>
        <Fragment>
          <Section
            title="Personal Details"
            addOns={{
              right: <EditDetailsButton route="/medensure/personal-details" />,
            }}
          >
            <div className="table-container">
              <table class="table is-fullwidth is-size-5">
                <tbody>
                  <tr>
                    <td className={classNames("has-text-weight-bold")}>
                      Company
                    </td>
                    <td className={classNames(styles["summary__tableData"])}>
                      {updatedPrescription?.company}
                    </td>
                  </tr>
                  <tr>
                    <td className={classNames("has-text-weight-bold")}>
                      First Name
                    </td>
                    <td className={classNames(styles["summary__tableData"])}>
                      {updatedPrescription?.firstName}
                    </td>
                  </tr>
                  <tr>
                    <td className={classNames("has-text-weight-bold")}>
                      Last Name
                    </td>
                    <td className={classNames(styles["summary__tableData"])}>
                      {updatedPrescription?.lastName}
                    </td>
                  </tr>
                  <tr>
                    <td className={classNames("has-text-weight-bold")}>
                      Employee ID
                    </td>
                    <td className={classNames(styles["summary__tableData"])}>
                      {updatedPrescription?.employeeNumber}
                    </td>
                  </tr>
                  <tr>
                    <td className={classNames("has-text-weight-bold")}>
                      Email
                    </td>
                    <td className={classNames(styles["summary__tableData"])}>
                      {updatedPrescription?.email}
                    </td>
                  </tr>
                  <tr>
                    <td className={classNames("has-text-weight-bold")}>
                      Mobile Number
                    </td>
                    <td className={classNames(styles["summary__tableData"])}>
                      {updatedPrescription?.mobileNumber}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Section>
          {selectedServices?.includes("Book a free teleconsult") ? (
            <Fragment>
              <Section
                title="Pre-teleconsult Health Survey"
                addOns={{
                  right: <EditDetailsButton route="/medensure/health-survey" />,
                }}
              >
                <div className="table-container">
                  <table class="table is-fullwidth is-size-5">
                    <tbody>
                      <tr>
                        <td className={classNames("has-text-weight-bold")}>
                          Maxicare Card Number
                        </td>
                        <td
                          className={classNames(styles["summary__tableData"])}
                        >
                          {updatedPrescription?.maxicareCardNumber}
                        </td>
                      </tr>
                      <tr>
                        <td className={classNames("has-text-weight-bold")}>
                          Height (cm/ft)
                        </td>
                        <td
                          className={classNames(styles["summary__tableData"])}
                        >
                          {updatedPrescription?.height?.value}
                        </td>
                      </tr>
                      <tr>
                        <td className={classNames("has-text-weight-bold")}>
                          Weight (kg/lbs)
                        </td>
                        <td
                          className={classNames(styles["summary__tableData"])}
                        >
                          {updatedPrescription?.weight?.value}
                        </td>
                      </tr>
                      <tr>
                        <td className={classNames("has-text-weight-bold")}>
                          Do you currently smoke?
                        </td>
                        <td
                          className={classNames(styles["summary__tableData"])}
                        >
                          {updatedPrescription?.frequencyOfSmoking}
                        </td>
                      </tr>
                      <tr>
                        <td className={classNames("has-text-weight-bold")}>
                          Medical History
                        </td>
                        <td
                          className={classNames(styles["summary__tableData"])}
                        >
                          {updatedPrescription?.medicalHistory?.join(", ")}
                        </td>
                      </tr>
                      {updatedPrescription?.otherMedicalHistory && (
                        <tr>
                          <td className={classNames("has-text-weight-bold")}>
                            Other Medical History
                          </td>
                          <td
                            className={classNames(styles["summary__tableData"])}
                          >
                            {updatedPrescription?.otherMedicalHistory}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className={classNames("has-text-weight-bold")}>
                          Family Medical History
                        </td>
                        <td
                          className={classNames(styles["summary__tableData"])}
                        >
                          {updatedPrescription?.familyMedicalHistory?.join(
                            ", "
                          )}
                        </td>
                      </tr>
                      {updatedPrescription?.otherFamilyMedicalHistory && (
                        <tr>
                          <td className={classNames("has-text-weight-bold")}>
                            Other Family Medical History
                          </td>
                          <td
                            className={classNames(styles["summary__tableData"])}
                          >
                            {updatedPrescription?.otherFamilyMedicalHistory}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className={classNames("has-text-weight-bold")}>
                          Maxicare Support
                        </td>
                        <td
                          className={classNames(styles["summary__tableData"])}
                        >
                          {updatedPrescription?.maxicareOfferings?.join(", ")}
                        </td>
                      </tr>
                      {updatedPrescription?.otherMaxicareOfferings && (
                        <tr>
                          <td className={classNames("has-text-weight-bold")}>
                            Other Maxicare Support
                          </td>
                          <td
                            className={classNames(styles["summary__tableData"])}
                          >
                            {updatedPrescription?.otherMaxicareOfferings}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className={classNames("has-text-weight-bold")}>
                          Remarks
                        </td>
                        <td
                          className={classNames(styles["summary__tableData"])}
                        >
                          {updatedPrescription?.remarks}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Section>
            </Fragment>
          ) : null}
          {selectedServices?.includes("Upload updated prescription") ? (
            <Fragment>
              <Section
                title="Prescriptions Uploaded"
                addOns={{
                  right: (
                    <EditDetailsButton route="/medensure/updated-prescription" />
                  ),
                }}
              >
                <div className="px-1 mx-1">
                  <div className="columns is-vcentered is-multiline">
                    {state.documents.map((file) => (
                      <div className="column is-6">
                        <FileThumbnail file={file} hasFileName />
                      </div>
                    ))}
                  </div>
                </div>
              </Section>
              <Section
                title="Prescription Details"
                addOns={{
                  right: (
                    <EditDetailsButton route="/medensure/updated-prescription" />
                  ),
                }}
              >
                <div className="table-container">
                  <table class="table is-fullwidth is-size-5">
                    <tbody>
                      <tr>
                        <td className={classNames("has-text-weight-bold")}>
                          Prescription Date
                        </td>
                        <td
                          className={classNames(styles["summary__tableData"])}
                        >
                          {updatedPrescription?.prescriptionDate?.month?.value}{" "}
                          {updatedPrescription?.prescriptionDate?.date?.value}
                          {", "}
                          {updatedPrescription?.prescriptionDate?.year?.value}
                        </td>
                      </tr>
                      <tr>
                        <td className={classNames("has-text-weight-bold")}>
                          Doctor's Name
                        </td>
                        <td
                          className={classNames(styles["summary__tableData"])}
                        >
                          {updatedPrescription?.prescribingDoctor}
                        </td>
                      </tr>
                      <tr>
                        <td className={classNames("has-text-weight-bold")}>
                          Doctor's PRC License
                        </td>
                        <td
                          className={classNames(styles["summary__tableData"])}
                        >
                          {updatedPrescription?.prcNumber}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Section>
              <Section
                title="Prescribed Medicines"
                addOns={{
                  right: (
                    <EditDetailsButton route="/medensure/updated-prescription" />
                  ),
                }}
              >
                {state.updatedPrescription.medicines.map((medicine) => (
                  <MedicineBox>
                    <p className="is-size-5 has-text-primary has-text-weight-bold">
                      {medicine.molecule}
                    </p>
                    <div className="is-flex is-justify-content-space-between">
                      <p className="is-size-6">
                        {medicine?.molecule === "Vitamin B complex"
                          ? ""
                          : medicine.strength}{" "}
                        {medicine.form}
                      </p>
                      <p className="is-size-6">
                        Taken {medicine.dailyIntake} per day
                      </p>
                    </div>
                  </MedicineBox>
                ))}
              </Section>
            </Fragment>
          ) : null}
          {selectedServices?.includes("Cancel refills") ? (
            <Section
              title="Discontinued Medicines"
              addOns={{
                right: <EditDetailsButton route="/medensure/cancel-refills" />,
              }}
            >
              {state.updatedPrescription.cancelledMedicines.map((medicine) => (
                <MedicineBox>
                  <p className="is-size-5 has-text-primary has-text-weight-bold">
                    {medicine.molecule}
                  </p>
                  <p className="is-size-6">
                    {medicine?.molecule === "Vitamin B complex"
                      ? ""
                      : medicine.strength}{" "}
                    {medicine.form}
                  </p>
                </MedicineBox>
              ))}
            </Section>
          ) : null}
          {selectedServices?.includes("Update personal details") ? (
            <Section
              title="Update Personal Details"
              addOns={{
                right: (
                  <EditDetailsButton route="/medensure/update-personal-details" />
                ),
              }}
            >
              <div className="table-container">
                <table class="table is-fullwidth is-size-5">
                  <tbody>
                    {updatePersonalDetails?.email && (
                      <tr>
                        <td className={classNames("has-text-weight-bold")}>
                          Email
                        </td>
                        <td
                          className={classNames(styles["summary__tableData"])}
                        >
                          {updatePersonalDetails?.email}
                        </td>
                      </tr>
                    )}
                    {updatePersonalDetails?.mobileNumber && (
                      <tr>
                        <td className={classNames("has-text-weight-bold")}>
                          Mobile Number
                        </td>
                        <td
                          className={classNames(styles["summary__tableData"])}
                        >
                          {updatePersonalDetails?.mobileNumber}
                        </td>
                      </tr>
                    )}
                    {updatePersonalDetails?.siteAddress?.value && (
                      <tr>
                        <td className={classNames("has-text-weight-bold")}>
                          Site Address
                        </td>
                        <td
                          className={classNames(styles["summary__tableData"])}
                        >
                          {updatePersonalDetails?.siteAddress?.value}
                        </td>
                      </tr>
                    )}
                    {updatePersonalDetails?.deliveryAddress?.streetAddress &&
                      addressType !== "Office" && (
                        <tr>
                          <td className={classNames("has-text-weight-bold")}>
                            Delivery Address
                          </td>
                          <td
                            className={classNames(styles["summary__tableData"])}
                          >
                            {!!houseNumber ? `${houseNumber}, ` : ""}
                            {!!streetAddress ? `${streetAddress} ` : ""}
                            {barangay?.value ? `, ${barangay?.value}` : ", "}
                            {city?.value ? `, ${city?.value}` : ", "}
                            {province?.value ? `, ${province?.value}` : ", "}
                            {notes ? `, ${notes}` : ""}
                          </td>
                        </tr>
                      )}
                    {updatePersonalDetails?.deliveryAddress?.officeAddress
                      ?.value &&
                      addressType === "Office" && (
                        <tr>
                          <td className={classNames("has-text-weight-bold")}>
                            Delivery Address
                          </td>
                          <td
                            className={classNames(styles["summary__tableData"])}
                          >
                            {officeAddress?.value
                              ? `${officeAddress?.value}`
                              : ""}
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </Section>
          ) : null}
        </Fragment>
        <ConsentAndAuthorization
          handleOnSubmit={handleOnSubmit}
          flow="epharmacy"
        >
          <ActionButtons
            submit={{
              label: "Submit",
              loading: loading,
              disabled: state.epharmacy.agreeToConsent.length === 0,
            }}
            back={{
              label: "Back",
              link: getBackPath(),
            }}
          />
        </ConsentAndAuthorization>
      </Container>
    </Layout>
  )
}

export default UpdatedPrescriptionSummary
