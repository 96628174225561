import Resizer from "react-image-file-resizer"
import moment from "moment"
import {
  checkIfHasRxRequired,
  accumulatePrice,
} from "../../Epharmacy/services/computations"

const MINIMUM_SIZE_TO_COMPRESS = 1048576

const generateFileName = (docType, index) => {
  const type = docType?.includes("SCPWD") ? "scpwd" : "rx"
  return `${type}_${moment().format("YYYYMMDD_HHmmss")}${
    index + 1 ? `_${index + 1}` : "0"
  }`
}

export const isPdf = (imgData) => {
  return (
    imgData.substring("data:".length, imgData.indexOf(";base64")) ===
    "application/pdf"
  )
}

export const checkIfUploadDisabled = (flow, state) => {
  const hasNoDocuments = state.documents.length === 0
  switch (flow) {
    case "epharmacy":
      const MAX_ORDER_TOTAL_LIMIT = 1000
      return (
        hasNoDocuments &&
        (checkIfHasRxRequired(state[flow].medicines) ||
          parseFloat(accumulatePrice(state[flow].medicines)) >=
            MAX_ORDER_TOTAL_LIMIT)
      )
    case "flexmed":
      return hasNoDocuments
    default:
      return !state.orderingFor
  }
}

export const isAlreadyUploaded = (file, filesUploaded) => {
  return filesUploaded.some((uploaded) => file.path === uploaded.path)
}

export const uploadDocument = (
  file,
  filesUploaded,
  docType,
  setFilesUploaded,
  callback,
  alreadyUploadedCallback,
  index
) => {
  let reader = new FileReader()
  if (file.type === "application/pdf" || file.size < MINIMUM_SIZE_TO_COMPRESS) {
    reader.readAsDataURL(file)
    reader.onload = () => {
      const newFile = {
        path: reader.result,
        name: generateFileName(file.type, docType, index),
        filename: generateFileName(file.type, docType, index),
        oldname: file.name,
        type: docType,
        size: file.size,
      }
      if (isAlreadyUploaded(newFile, filesUploaded) && alreadyUploadedCallback)
        alreadyUploadedCallback(newFile)
      else
        setFilesUploaded((fileList) => {
          let previousFilesUploaded = [...fileList]
          previousFilesUploaded.push(newFile)
          if (callback) callback(previousFilesUploaded, newFile)
          return previousFilesUploaded
        })
    }
  } else
    Resizer.imageFileResizer(
      file,
      1000,
      1000,
      "JPEG",
      100,
      0,
      (uri) => {
        const newFile = {
          path: uri,
          name: generateFileName(file.type, docType),
          filename: generateFileName(file.type, docType),
          oldname: file.name,
          type: docType,
          size: file.size,
        }
        if (
          isAlreadyUploaded(newFile, filesUploaded) &&
          alreadyUploadedCallback
        )
          alreadyUploadedCallback(newFile)
        else
          setFilesUploaded((fileList) => {
            let previousFilesUploaded = [...fileList]
            previousFilesUploaded.push(newFile)
            if (callback) callback(previousFilesUploaded, newFile)
            return previousFilesUploaded
          })
      },
      "base64"
    )
}

export const hasFilesUploaded = (files) => {
  if (files.length > 0) return true
  else return false
}
